<template>
  <div class="pt-20">
    <div
      class="md:w-1024 mx-auto py-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
    >
      <div
        class="w-full text-left text-textBlack flex flex-col md:flex-row items-start justify-between"
      >
        <!-- <video class="h-44" rel="prefetch" autoplay loop muted playsinline>
          <source
            src="~@/assets/videos/Partnervideosmallersize.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video> -->
        <img
          class="h-72"
          src="@/assets/images/icons/neoCertification.jpeg"
          alt="neoCertification"
        />
        <div class="pl-4">
          <h1 class="mb-4 text-4xl font-semibold text-primary">
            Join our Team of neo Agents Today!
          </h1>
          <p class="w-full text-base text-textBlack text-justify">
            Become part of a select group of authorized neo agents and take
            advantage of the unique opportunity our advanced English language
            learning (ELL) solution offers people from all over the globe. neo
            provides much-needed solutions for English learners that need to
            reach their fluency goals quickly and effectively. If you are
            interested in joining our team, fill out the form below, or contact
            us at agent@nexgenenglishonline.co or via LinkedIn.​
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>